import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Tabs, Tab } from '@mui/material';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { theme } from '../styles/theme';
import Spinner from './Spinner';
import { analytics } from '../firebase'
import { logEvent } from 'firebase/analytics';


const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
`;

const ZeitgeistContainer = styled.div`
  padding: 20px;
  background-color: ${theme.colors.background};
  font-family: ${theme.typography.fontFamily};
`;

const Title = styled.h1`
  color: ${theme.colors.primary};
  font-size: ${theme.typography.headerSize};
  margin-bottom: 20px;
`;

const TopicGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  max-width: 800px;
  margin: 20px auto;
`;

const TopicChip = styled.div<{ intensity: number }>`
  background-color: ${(props) => `rgba(0, 0, 255, ${props.intensity})`};
  color: white;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  word-break: break-word;
`;

const TopicChipLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: contents;
`;

const DaysSelect = styled.select`
  margin-left: 20px;
  padding: 5px;
  font-size: 16px;
`;

interface TopicFrequency {
  topic: string;
  count: number;
}

const Zeitgeist: React.FC = () => {
  const [topicFrequencies, setTopicFrequencies] = useState<TopicFrequency[]>([]);
  const [subtopicFrequencies, setSubtopicFrequencies] = useState<TopicFrequency[]>([]);
  const [speakerFrequencies, setSpeakerFrequencies] = useState<TopicFrequency[]>([]);
  const [tabValue, setTabValue] = useState(0);
  const [days, setDays] = useState(7);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchZeitgeist = async () => {
      setLoading(true);
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          console.error('User not authenticated');
          setLoading(false);
          return;
        }

        const idToken = await user.getIdToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/zeitgeist?days=${days}`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setTopicFrequencies(data.topics);
        setSubtopicFrequencies(data.subtopics);
        setSpeakerFrequencies(data.speakers);
        logEvent(analytics, 'page_view', {
          page_title: 'Zeitgeist',
          page_location: window.location.href,
          user_id: user.uid
        });
      } catch (error) {
        console.error('Error fetching zeitgeist data:', error);
      } finally {
        setLoading(false);
      }
      
    };
    fetchZeitgeist();
  }, [days]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const renderGrid = (frequencies: TopicFrequency[], type: string) => {
    const maxCount = Math.max(...frequencies.map((tf) => tf.count), 1);

    return (
      <TopicGrid>
        {frequencies.map(({ topic, count }) => (
          <TopicChipLink key={topic} to={`/topic/${encodeURIComponent(topic)}?type=${type}`}>
            <TopicChip intensity={count / maxCount}>
              {topic} ({count})
            </TopicChip>
          </TopicChipLink>
        ))}
      </TopicGrid>
    );
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <ZeitgeistContainer>
      <Title>
        Zeitgeist (Last {days} Days)
        <DaysSelect value={days} onChange={(e) => setDays(Number(e.target.value))}>
          <option value={7}>7 days</option>
          <option value={14}>14 days</option>
          <option value={30}>30 days</option>
        </DaysSelect>
      </Title>
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Topics" />
        <Tab label="Subtopics" />
        <Tab label="Speakers" />
      </Tabs>
      {tabValue === 0 && renderGrid(topicFrequencies, 'topics')}
      {tabValue === 1 && renderGrid(subtopicFrequencies, 'subtopics')}
      {tabValue === 2 && renderGrid(speakerFrequencies, 'speakers')}
    </ZeitgeistContainer>
  );
};

export default Zeitgeist;
