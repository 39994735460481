import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import { SelectChangeEvent } from '@mui/material';
import React, { useMemo, useState, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import GeneratedContentList from './components/GeneratedContentList';
import InfluencerAgents from './components/InfluencerAgents';
import Spinner from './components/Spinner';
import Toolbar from './components/Toolbar';
import TopicAssets from './components/TopicAssets';
import VideoList from './components/VideoList';
import Zeitgeist from './components/Zeitgeist';
import { useOrganizations } from './organizationUtils';
import {
  AdvancedOptions,
  AdvancedOptionsToggle,
  AppContainer,
  BannerText,
  Button,
  ContentContainer,
  Form,
  MaxResultsLabel,
  MaxResultsSelect,
  MaxResultsWrapper,
  OptionsBar,
  ScrollingBanner,
  ScrollingText,
  SearchBar,
  SearchButton,
  SearchContainer,
  SearchInput,
  SearchTypeToggle,
  SpinnerWrapper,
  Title,
  ToggleButton,
  LibraryToggle,
} from './styles/App.style';
import { analytics } from './firebase'
import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import useAuth from './useAuth';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, loading, login } = useAuth();

  if (loading) {
    return <Spinner />;
  }

  if (!user) {
    return <Button onClick={login}>Login with Google</Button>;
  }

  return <>{children}</>;
};

const App: React.FC = () => {
  const [assets, setAssets] = useState<{ id: string }[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { user, loading: authLoading, login } = useAuth();
  const [filterType, setFilterType] = useState<string>('none');
  const [filterValue, setFilterValue] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<string>('assetFinder');
  const { organizations, loading: orgLoading } = useOrganizations();
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [localSearchType, setLocalSearchType] = useState<string>('similarity');
  const [localK, setLocalK] = useState<number>(10);
  const [library, setLibrary] = useState<string>('all');

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(analytics, user.uid);
        setUserProperties(analytics, {
          account_created_at: user.metadata.creationTime,
          last_sign_in_at: user.metadata.lastSignInTime,
        });
        logEvent(analytics, 'login', {
          method: user.providerData[0]?.providerId || 'unknown',
        });
      } else {
        setUserId(analytics, null);
        logEvent(analytics, 'logout');
      }
    });

    return () => unsubscribe();
  }, []);

  const memoizedVideoList = useMemo(() => {
    if (assets.length > 0) {
      return <VideoList assetIds={assets} filterType={filterType} filterValue={filterValue} />;
    }
    return null;
  }, [assets, filterType, filterValue]);

  const memoizedAdvancedOptions = useMemo(() => {
    if (showAdvanced) {
      return (
        <AdvancedOptions>
          <SearchTypeToggle>
            <ToggleButton
              active={localSearchType === 'similarity'}
              onClick={() => setLocalSearchType('similarity')}
              type="button"
            >
              Similarity
            </ToggleButton>
            <ToggleButton
              active={localSearchType === 'mmr'}
              onClick={() => setLocalSearchType('mmr')}
              type="button"
            >
              MMR
            </ToggleButton>
          </SearchTypeToggle>
          <MaxResultsWrapper>
            <MaxResultsLabel>Max Results</MaxResultsLabel>
            <MaxResultsSelect value={localK} onChange={(e) => setLocalK(Number(e.target.value))}>
              {[5, 10, 20, 50, 100].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </MaxResultsSelect>
          </MaxResultsWrapper>
        </AdvancedOptions>
      );
    }
    return null;
  }, [showAdvanced, localSearchType, localK]);

  const handleLogin = () => {
    login();
  };

  const checkAuth = () => {
    if (authLoading) {
      return <Spinner />;
    }
    if (!user) {
      return <Button onClick={handleLogin}>Login with Google</Button>;
    }
    return null;
  };

  const fetchVideos = async () => {
    console.log('Fetching videos...');
    if (!user) return;
    setLoading(true);
    setHasSearched(true);
    try {
      const idToken = await user.getIdToken();
      const API_URL = process.env.REACT_APP_API_URL;
      const url = new URL(`${API_URL}/video_ids`);
      url.searchParams.append('query', searchQuery);
      url.searchParams.append('search_type', localSearchType);
      url.searchParams.append('library', library);
      url.searchParams.append('k', localK.toString());

      const response = await fetch(url.toString(), {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      const data = await response.json();
      if (!('results' in data)) {
        console.error('Error: invalid API response');
        return;
      }
      console.log(data);
      logEvent(analytics, 'search', {
        search_term: searchQuery,
        search_type: localSearchType,
        library: library,
        results_count: data.results.length,
        user_id: user.uid
      });
      
      setAssets(data.results);
    } catch (error) {
      console.error('Error fetching videos:', error);
      logEvent(analytics, 'search_error', {
        error_message: error instanceof Error ? error.message : 'Unknown error',
        user_id: user.uid
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    console.log('Form submitted');
    e.preventDefault();
    fetchVideos();
  };

  const handleNavigate = (page: string) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterType(event.target.value as string);
  };

  const renderContent = () => {
    return (
      <>
        <SearchContainer>
          <Title>Query the Machine</Title>
          <Form onSubmit={handleSubmit}>
            <SearchBar>
              <SearchInput
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Enter your query here"
              />
              <SearchButton type="submit">
                <SearchIcon />
              </SearchButton>
            </SearchBar>
            <OptionsBar>
              <LibraryToggle>
                <ToggleButton
                  active={library === 'all'}
                  onClick={() => setLibrary('all')}
                  type="button"
                >
                  All
                </ToggleButton>
                <ToggleButton
                  active={library === 'events'}
                  onClick={() => setLibrary('events')}
                  type="button"
                >
                  Events
                </ToggleButton>
                <ToggleButton
                  active={library === 'media'}
                  onClick={() => setLibrary('media')}
                  type="button"
                >
                  Media
                </ToggleButton>
                <ToggleButton
                  active={library === 'streams'}
                  onClick={() => setLibrary('streams')}
                  type="button"
                >
                  Streams
                </ToggleButton>
              </LibraryToggle>
              <AdvancedOptionsToggle
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowAdvanced(!showAdvanced);
                }}
              >
                <SettingsIcon /> Advanced
              </AdvancedOptionsToggle>
            </OptionsBar>
            {showAdvanced && memoizedAdvancedOptions}
          </Form>
        </SearchContainer>
        {loading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : memoizedVideoList ? (
          memoizedVideoList
        ) : (
          hasSearched && <p>No results found</p>
        )}
      </>
    );
  };

  return (
    <Router>
      <AppContainer>
        <ScrollingBanner>
          <ScrollingText>
            <BannerText>THIS IS IN ACTIVE DEVELOPMENT - SORRY IF THINGS BREAK &lt;3</BannerText>
          </ScrollingText>
        </ScrollingBanner>
        <Toolbar />
        <ContentContainer>
          <Routes>
            <Route path="/" element={<ProtectedRoute>{renderContent()}</ProtectedRoute>} />
            <Route
              path="/influencer-agents"
              element={
                <ProtectedRoute>
                  <InfluencerAgents />
                </ProtectedRoute>
              }
            />
            <Route
              path="/generated-content/:agentId"
              element={
                <ProtectedRoute>
                  <GeneratedContentList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/zeitgeist"
              element={
                <ProtectedRoute>
                  <Zeitgeist />
                </ProtectedRoute>
              }
            />
            <Route
              path="/topic/:topic"
              element={
                <ProtectedRoute>
                  <TopicAssets />
                </ProtectedRoute>
              }
            />
            <Route
              path="/speaker/:speaker"
              element={
                <ProtectedRoute>
                  <TopicAssets />
                </ProtectedRoute>
              }
            />
            <Route
              path="/subtopic/:subtopic"
              element={
                <ProtectedRoute>
                  <TopicAssets />
                </ProtectedRoute>
              }
            />
          </Routes>
        </ContentContainer>
      </AppContainer>
    </Router>
  );
};

export default App;
