import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  Timestamp,
  updateDoc,
  doc,
  writeBatch,
} from 'firebase/firestore';
import { getAuth, User } from 'firebase/auth';
import { db } from '../firebase';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { theme } from '../styles/theme';

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.colors.background};
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: ${theme.typography.fontFamily};
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)`
  margin-right: 20px;
`;

const NotificationIcon = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 20px;
  font-size: 24px;
`;

const NotificationBadge = styled.span`
  background-color: ${theme.colors.primary};
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  position: absolute;
  top: -5px;
  right: -5px;
`;

const NotificationDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  min-width: 250px;
`;

const NotificationItem = styled.div<{ read: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  background-color: ${(props) => (props.read ? '#f9f9f9' : 'white')};
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: ${(props) => (props.read ? '#f0f0f0' : '#f5f5f5')};
  }
  font-family: ${theme.typography.fontFamily};
  font-size: 12px;
`;

const NotificationText = styled.div`
  flex-grow: 1;
  font-family: ${theme.typography.fontFamily};
  font-size: 12px;
`;

const StyledLink = styled.a`
  color: ${theme.colors.primary};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.secondary};
  }
`;

const NotificationIconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  margin-right: 5px;
  color: ${theme.colors.lightText};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${theme.colors.primary};
  }
`;

const StyledNotificationsIcon = styled(NotificationsIcon)`
  font-size: 24px;
`;

const NotificationActions = styled.div`
  display: flex;
  align-items: center;
`;

const ProfilePhoto = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const MenuItemLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 6px 16px;
`;

const NotificationMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #eee;
`;

const NotificationActionButton = styled.button`
  background-color: ${theme.colors.primary};
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${theme.colors.secondary};
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

interface EditRequestNotification {
  id: string;
  assetId: string;
  status: string;
  createdAt: Timestamp;
  editedVideoUrl?: string;
  read: boolean;
  dismissed?: boolean;
}

const Toolbar: React.FC = () => {
  const [notifications, setNotifications] = useState<EditRequestNotification[]>([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    if (user) {
      const q = query(collection(db, 'edit_requests'), where('userId', '==', user.uid));

      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const newNotifications: EditRequestNotification[] = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            newNotifications.push({
              id: doc.id,
              assetId: data.assetId,
              status: data.status,
              createdAt: data.createdAt,
              read: data.read || false,
              editedVideoUrl: data.editedVideoUrl || null,
              dismissed: data.dismissed || false,
            });
          });
          setNotifications(
            newNotifications.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()),
          );
        },
        (error) => {
          console.error('Error fetching notifications:', error);
        },
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user]);

  const markAsRead = async (notificationId: string) => {
    try {
      const notificationRef = doc(db, 'edit_requests', notificationId);
      await updateDoc(notificationRef, { read: true });
      console.log('Notification marked as read:', notificationId);
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const dismissNotification = async (notificationId: string) => {
    try {
      const notificationRef = doc(db, 'edit_requests', notificationId);
      await updateDoc(notificationRef, { dismissed: true });
      console.log('Notification dismissed:', notificationId);
    } catch (error) {
      console.error('Error dismissing notification:', error);
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const markAllAsRead = async () => {
    try {
      const batch = writeBatch(db);
      notifications.forEach((notification) => {
        if (!notification.read) {
          const notificationRef = doc(db, 'edit_requests', notification.id);
          batch.update(notificationRef, { read: true });
        }
      });
      await batch.commit();
      console.log('All notifications marked as read');
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  const deleteAllNotifications = async () => {
    try {
      const batch = writeBatch(db);
      notifications.forEach((notification) => {
        const notificationRef = doc(db, 'edit_requests', notification.id);
        batch.update(notificationRef, { dismissed: true });
      });
      await batch.commit();
      console.log('All notifications deleted');
    } catch (error) {
      console.error('Error deleting all notifications:', error);
    }
  };

  return (
    <ToolbarContainer>
      <LeftSection>
        <StyledIconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
          <MenuIcon />
        </StyledIconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose}>
            <MenuItemLink to="/">Asset Finder</MenuItemLink>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <MenuItemLink to="/influencer-agents">Influencer Agents</MenuItemLink>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <MenuItemLink to="/zeitgeist">Zeitgeist</MenuItemLink>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <MenuItemLink to="https://app.blueroseresearch.org/predictive-scoring">Blue Rose Predictive Scoring</MenuItemLink>
          </MenuItem>
        </Menu>
      </LeftSection>
      <RightSection>
        <NotificationIcon onClick={() => setShowNotifications(!showNotifications)}>
          <StyledNotificationsIcon />
          {notifications.filter((n) => !n.read && !n.dismissed).length > 0 && (
            <NotificationBadge>
              {notifications.filter((n) => !n.read && !n.dismissed).length}
            </NotificationBadge>
          )}
          {showNotifications && (
            <NotificationDropdown>
              <NotificationMenuHeader>
                <NotificationActionButton onClick={markAllAsRead}>
                  Mark All as Read
                </NotificationActionButton>
                <NotificationActionButton onClick={deleteAllNotifications}>
                  Delete All
                </NotificationActionButton>
              </NotificationMenuHeader>
              {notifications.filter((n) => !n.dismissed).length > 0 ? (
                notifications
                  .filter((n) => !n.dismissed)
                  .map((notification) => (
                    <NotificationItem key={notification.id} read={notification.read}>
                      <NotificationActions>
                        <NotificationIconButton
                          onClick={() => dismissNotification(notification.id)}
                          title="Dismiss"
                        >
                          <CloseIcon fontSize="small" />
                        </NotificationIconButton>
                        {!notification.read && (
                          <NotificationIconButton
                            onClick={() => markAsRead(notification.id)}
                            title="Mark as Read"
                          >
                            <CheckCircleIcon fontSize="small" />
                          </NotificationIconButton>
                        )}
                      </NotificationActions>
                      <NotificationText>
                        {notification.status === 'completed' ? (
                          <>
                            Edit request {notification.assetId} completed :{' '}
                            <StyledLink
                              href={notification.editedVideoUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                            >
                              download video
                            </StyledLink>
                          </>
                        ) : (
                          <>
                            Edit request for asset {notification.assetId} is {notification.status}
                          </>
                        )}
                        <br />
                        <small>{notification.createdAt.toDate().toLocaleString()}</small>
                      </NotificationText>
                    </NotificationItem>
                  ))
              ) : (
                <NotificationItem read={false}>No notifications</NotificationItem>
              )}
            </NotificationDropdown>
          )}
        </NotificationIcon>
        {user && user.photoURL && <ProfilePhoto src={user.photoURL} alt="Profile" />}
      </RightSection>
    </ToolbarContainer>
  );
};

export default Toolbar;
