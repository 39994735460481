import { getAuth } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../styles/theme';
import Spinner from './Spinner';
import VideoList from './VideoList';

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
`;

const Container = styled.div`
  padding: 20px;
  background-color: ${theme.colors.background};
  font-family: ${theme.typography.fontFamily};
`;

const Title = styled.h1`
  color: ${theme.colors.primary};
  font-size: ${theme.typography.headerSize};
  margin-bottom: 20px;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TopicOrSpeakerAssets: React.FC = () => {
  const { topic } = useParams<{ topic: string }>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type') || 'topics';

  const [assetIds, setAssets] = useState<{ id: string }[]>([]);
  const [loading, setLoading] = useState(true);
  const [days, setDays] = useState(30);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    const fetchAssets = async () => {
      if (!topic) {
        console.error('No topic provided');
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          console.error('User not authenticated');
          setLoading(false);
          return;
        }

        const idToken = await user.getIdToken();
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/topic_assets/${encodeURIComponent(topic)}?type=${type}&days=${days}`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          },
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const fetchedAssets = await response.json();
        setAssets(fetchedAssets.results);
      } catch (error) {
        console.error('Error fetching assets:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssets();
  }, [topic, type, days]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  if (!topic) {
    return <Container>No topic specified</Container>;
  }

  if (loading) {
    return <Spinner />;
  }

  const getTitle = () => {
    switch (type) {
      case 'topics':
        return `Topic: ${topic}`;
      case 'subtopics':
        return `Subtopic: ${topic}`;
      case 'speakers':
        return `Speaker: ${topic}`;
      default:
        return `${topic}`;
    }
  };

  return (
    <Container>
      <Title>{getTitle()}</Title>
      <select value={days} onChange={(e) => setDays(Number(e.target.value))}>
        <option value={7}>7 days</option>
        <option value={14}>14 days</option>
        <option value={30}>30 days</option>
      </select>
      <SearchBar
        type="text"
        placeholder="Search in transcripts or descriptions..."
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <VideoList assetIds={assetIds} filterType="search" filterValue={searchQuery} />
    </Container>
  );
};

export default TopicOrSpeakerAssets;
