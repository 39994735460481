import React, { useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  FormControl,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  Typography,
  Slider,
  Tooltip,
  Card,
  CardContent,
  IconButton,
  Box,
} from '@mui/material';
import { EditingConfig, Overlay } from 'shared-models';
import { StyledDialog, DialogButton } from '../styles/VideoList.style';
import styled from 'styled-components';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import LayersIcon from '@mui/icons-material/Layers';

const StyledCard = styled(Card)`
  margin-bottom: 16px;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const StyledSlider = styled(Slider)`
  margin-top: 8px;
`;

const CardTitle = styled(Typography)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
`;

const IconWrapper = styled.span`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

interface EditingDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (config: EditingConfig) => void;
  overlays: Overlay[];
}

const EditingDialog: React.FC<EditingDialogProps> = ({ open, onClose, onSubmit, overlays }) => {
  const [config, setConfig] = useState<EditingConfig>({
    face_tracking: false,
    captioning: false,
    upscale: false,
    scaling: 'letterbox',
    target_resolution: [608, 1080],
    maintain_height: true,
    tracking_resolution: [1280, 720],
    buffer: 0.6,
    slideshow: false,
  });

  const [showAdvanced, setShowAdvanced] = useState(false);

  const handleChange = (field: keyof EditingConfig, value: any) => {
    setConfig((prev) => ({ ...prev, [field]: value }));

    if (field === 'face_tracking') {
      setConfig((prev) => ({
        ...prev,
        scaling: value ? 'scaled' : prev.scaling,
      }));
    }
  };

  const handleSubmit = () => {
    onSubmit(config);
    onClose();
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h5">Edit Video</Typography>
      </DialogTitle>
      <DialogContent>
        {/* Video Processing Section */}
        <StyledCard>
          <CardContent>
            <CardTitle variant="h6">
              <IconWrapper>
                <VideoSettingsIcon />
              </IconWrapper>
              Video Processing
            </CardTitle>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={config.face_tracking}
                    onChange={(e) => handleChange('face_tracking', e.target.checked)}
                    color="primary"
                  />
                }
                label="Enable Face Tracking"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={config.captioning}
                    onChange={(e) => handleChange('captioning', e.target.checked)}
                    color="primary"
                  />
                }
                label="Add Captions"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={config.slideshow}
                    onChange={(e) => handleChange('slideshow', e.target.checked)}
                    color="primary"
                  />
                }
                label="Enable Slideshow"
              />
            </FormGroup>
          </CardContent>
        </StyledCard>

        {/* Video Scaling Section */}
        <StyledCard>
          <CardContent>
            <CardTitle variant="h6">
              <IconWrapper>
                <AspectRatioIcon />
              </IconWrapper>
              Video Scaling
            </CardTitle>
            <StyledFormControl>
              <Select
                value={config.scaling}
                onChange={(e) => handleChange('scaling', e.target.value)}
                disabled={config.face_tracking}
              >
                <MenuItem value="scaled">Scaled</MenuItem>
                <MenuItem value="letterbox">Letterbox</MenuItem>
              </Select>
            </StyledFormControl>
          </CardContent>
        </StyledCard>

        {/* Overlay Section */}
        <StyledCard>
          <CardContent>
            <CardTitle variant="h6">
              <IconWrapper>
                <LayersIcon />
              </IconWrapper>
              Overlay
            </CardTitle>
            <StyledFormControl>
              <Select
                value={config.overlay?.description || ''}
                onChange={(e) =>
                  handleChange(
                    'overlay',
                    overlays.find((o) => o.description === e.target.value) || null
                  )
                }
              >
                <MenuItem value="">None</MenuItem>
                {overlays.map((overlay) => (
                  <MenuItem key={overlay.description} value={overlay.description}>
                    {overlay.description}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </CardContent>
        </StyledCard>

        {/* Advanced Options Section */}
        <StyledCard>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <CardTitle variant="h6">
                <IconWrapper>
                  <SettingsIcon />
                </IconWrapper>
                Advanced Options
              </CardTitle>
              <IconButton onClick={() => setShowAdvanced(!showAdvanced)} size="small">
                {showAdvanced ? '-' : '+'}
              </IconButton>
            </Box>
            {showAdvanced && (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  Target Resolution
                </Typography>
                <Typography variant="body2">Width: {config.target_resolution[0]} px</Typography>
                <StyledSlider
                  value={config.target_resolution[0]}
                  onChange={(_, value) =>
                    handleChange('target_resolution', [value as number, config.target_resolution[1]])
                  }
                  min={480}
                  max={3840}
                  step={1}
                />
                <Typography variant="body2">Height: {config.target_resolution[1]} px</Typography>
                <StyledSlider
                  value={config.target_resolution[1]}
                  onChange={(_, value) =>
                    handleChange('target_resolution', [config.target_resolution[0], value as number])
                  }
                  min={360}
                  max={2160}
                  step={1}
                />
                <Typography variant="subtitle1" gutterBottom>
                  Clip Buffer
                </Typography>
                <Tooltip title="Start and end of clip buffer time">
                  <div>
                    <Typography variant="body2">Buffer: {config.buffer} seconds</Typography>
                    <StyledSlider
                      value={config.buffer}
                      onChange={(_, value) => handleChange('buffer', value as number)}
                      min={0}
                      max={1}
                      step={0.1}
                    />
                  </div>
                </Tooltip>
                <FormControlLabel
                  control={
                    <Switch
                      checked={config.maintain_height}
                      onChange={(e) => handleChange('maintain_height', e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Maintain Clip Height During Tracking"
                />
              </>
            )}
          </CardContent>
        </StyledCard>
      </DialogContent>
      <DialogActions>
        <DialogButton onClick={onClose} color="secondary">
          Cancel
        </DialogButton>
        <DialogButton onClick={handleSubmit} color="primary">
          Submit
        </DialogButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default EditingDialog;

