import styled from 'styled-components';
import { theme } from './theme';

import {
  IconButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
} from '@mui/material';
import Video from 'src/components/Video';

export const RatingContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
`;

export const RatingSum = styled.span`
  margin: 0 10px;
  font-weight: bold;
  font-family: ${theme.typography.fontFamily};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const VideoCardDiv = styled.div`
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: ${theme.typography.fontFamily};
`;

export const StyledVideo = styled(Video)`
  width: 100%;
  height: 300px;
  border-radius: 8px;
  margin-bottom: 10px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    font-size: ${theme.typography.fontSize};
  }

  th {
    background-color: #f4f4f4;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const StyledButton = styled.a`
  display: inline-block;
  padding: 12px 20px;
  font-size: ${theme.typography.fontSize};
  color: #fff;
  background-color: ${theme.colors.primary};
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.secondary};
  }
`;

export const DownloadButton = styled(StyledButton)``;

export const SubmitButton = styled(StyledButton).attrs({
  as: 'button',
})``;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledDialog = styled(MuiDialog)`
  .MuiPaper-root {
    background-color: ${theme.colors.background};
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;

export const DialogTitle = styled(MuiDialogTitle)`
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.headerSize};
  color: ${theme.colors.text};
`;

export const DialogContent = styled(MuiDialogContent)`
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize};
  color: ${theme.colors.text};
  padding: 20px;
`;

export const DialogActions = styled(MuiDialogActions)`
  padding: 20px;
`;

export const DialogButton = styled(StyledButton)`
  margin: 0 10px;

  &:last-child {
    margin-right: 0;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const DateAdded = styled.span`
  font-size: 0.9em;
  color: ${theme.colors.lightText};
  font-family: ${theme.typography.fontFamily};
`;

export const FlagButton = styled(IconButton)`
  margin-left: auto;
  margin-right: 10px;
`;
